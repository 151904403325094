@import "./../../shared.scss";

.login {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.login-form {
  padding-top: $base-space;
  padding-bottom: $base-space;
  margin-top: 120px !important;
}

.login-error {
  margin-top: $base-space;
  margin-bottom: 0;
}
