@import "./../../shared.scss";


/*--------------------------------------------------------------
# Counts
--------------------------------------------------------------*/
.counts {
  background: $color-background-lightblue;

  &.section {
    padding: 2 * $base-space 0;
  }

  .count-box {
    padding: 30px 30px 25px 30px;
    width: 100%;
    position: relative;
    text-align: center;
    background: #fff;
  }

  .count-box i {
    position: absolute;
    top: -25px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 20px;
    background: $color-blue;
    color: #fff;
    border-radius: 50px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
  }

  .count-box span {
    font-size: 36px;
    display: block;
    font-weight: 600;
    color: #082744;
  }

  .count-box p {
    padding: 0;
    margin: 0;
    font-family: "Raleway", sans-serif;
    font-size: 14px;
  }

}
