@import "./../../shared.scss";

.default-layout {
  .sidebar {
    border-right: 2px solid #f5f5f5;
    box-shadow: none !important;
    overflow: hidden;

    .nav-item {
      border-bottom: 2px solid #f5f5f5;
      font-weight: bold;
    }

    i {
      padding: 0 $base-space 0 $base-space;
    }

    a {
      padding: $base-space $base-space / 2 $base-space $base-space / 2;
    }

    a.active {
      background-color: $color-blue;
    }
  }
}
