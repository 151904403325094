@import "./../shared.scss";

.unauthorized {
  background: linear-gradient(to bottom right, #EEE, #AAA);
  margin-top: -($base-space * 3) !important;

  h1 {
    margin: 40px 0 20px;
  }
}
