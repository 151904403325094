@import "./../../shared.scss";

.recording-search {
  .recording-actions {
    margin-left: $base-space / 4;
    display: inline-flex;

    button {
      //min-width: 100px;
    }
  }

  tr {
    vertical-align: middle !important;
  }

  .search-filters {
    color: #0d6efd;
  }

  .filters {
    .btn {
      border-radius: 0;
    }
  }

  .all-actions {
    button {
      min-width: 150px;
    }

    .download-all {
      margin-right: $base-space;
    }
  }
}
