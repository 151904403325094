@import "./../../shared.scss";

/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
.default-layout {
  #header {
    background: #fff;
    transition: all 0.5s;
    z-index: 997;
    padding: 15px 0;
    box-shadow: 0px 2px 15px rgba(25, 119, 204, 0.1);

    .logo {
      font-size: 30px;
      margin: 0;
      padding-left: $base-space;
      font-weight: 700;
      letter-spacing: 0.5px;
      font-family: "Poppins", sans-serif;
    }

    .logo a {
      color: #2c4964;
    }

    .logo img {
      max-height: 40px;
    }
  }

  a {
    color: $color-font;
  }

  .icons {
    padding: 0 $base-space 0 $base-space;
    cursor: pointer;
  }

  .user {
    color: $color-blue;

    .username {
      font-weight: bold;
      font-size: $extra-large-text;
      padding-left: $base-space / 4;
    }
  }

  .notification-number {
    margin-left: 5px;
  }
}
