@import "./../../shared.scss";

.cli-change-profile {
  .dataTables_wrapper .dataTables_processing {
    //position: relative;
    //top: 5%;
  }

  .cli-number-actions {
    margin-left: $base-space / 4;
    display: inline-flex;

    button {
      min-width: 100px;
    }
  }

  tr {
    vertical-align: middle !important;
  }

  .download {
    color: #0d6efd;
  }
}
