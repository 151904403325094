@import "./../../shared.scss";

.list-of-recording {
  .recording-actions {
    margin-left: $base-space / 4;
    display: inline-flex;

    button {
      min-width: 100px;
    }
  }

  tr {
    vertical-align: middle !important;
  }
}
