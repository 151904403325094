@import "./shared.scss";

body {
  font-family: $base-font !important;
  color: $color-font;
}

a {
  color: $color-blue;
  text-decoration: none;
}

a:hover {
  color: $color-blue-light;
  text-decoration: none;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Raleway", sans-serif;
}

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: .3rem;
}

.form-switch {
  font-size: 25px;
}

/*--------------------------------------------------------------
# Sections General
--------------------------------------------------------------*/
.section {
  padding-top: $base-space;
  padding-bottom: $base-space * 2;

  .section-title {
    text-align: center;
  }

  .section-title h2 {
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 20px;
    padding-bottom: 20px;
    position: relative;
    color: #2c4964;
  }

  .section-title h2::before {
    content: '';
    position: absolute;
    display: block;
    width: 120px;
    height: 1px;
    background: #ddd;
    bottom: 1px;
    left: calc(50% - 60px);
  }

  .section-title h2::after {
    content: '';
    position: absolute;
    display: block;
    width: 40px;
    height: 3px;
    background: $color-blue;
    bottom: 0;
    left: calc(50% - 20px);
  }
}
