@import "./../../shared.scss";

.dialer-profile {
  .dialer-destination-actions {
    margin-left: $base-space / 4;
    display: inline-flex;

    button {
      //min-width: 100px;
    }
  }

  tr {
    vertical-align: middle !important;
  }

  .cloned-link {
    color: $color-blue;
  }

  .cloned-link:hover {
    color: $color-blue-light;
  }
}
